/* color palette:  
    f1e6e1 (seashell)
    ebdcd5 (champagne pink)
    ddafa1 (melon)
    7d4f50 (rose taupe)
    27637c (payne's gray)
    9fa68c (sage)
*/

/** to override the background color */
body {
  background-color: #ebdcd5 !important;
  min-height: 100vh;
}

.font {
  font-family: "Roboto Serif", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  /* from 100-900 */
  font-style: normal;
  font-variation-settings:
      "wdth" 100,
      "GRAD" 0;
}

.love-ya-like-a-sister-regular {
  font-family: "Love Ya Like A Sister", cursive;
  font-weight: 500;
  font-style: normal;
}

.navbar {
  /* navbar background */
  background-color: rgba(235, 220, 213, 0.5);
}

.navbar-nav .dropdown-menu {
  background-color: #ebdcd5;
}

.navbar-nav .navLink,
/* navbar titles */
.navbar-nav .nav-link,
/* dropdown title */
.dropdown-toggle::after,
/* dropdown arrow */
.navbar-nav .nav-link:focus,
.navbar-nav .dropdown-item
/* dropdown items */
  {
  color: #27637c;
}

/* Change the text & background color on hover */
.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.show,
.navbar-nav .dropdown-item:hover {
  color: #ebdcd5 !important;
  background-color: rgba(39, 99, 124, 0.75);
  transition: color 0.5s ease;
}

.image-style {
  width: 100%;
  height: auto;
  max-width: 500px;
  border-radius: 20px;
  opacity: .85;
}

.homepage-hi {
  display: inline-flex;
  align-items: center;
  color: #9fa68c;
  font-family: "Love Ya Like A Sister", cursive;
  font-weight: 500;
  font-size: 2.5em;
}

.text {
  font-weight: 500;
  color: #27637c;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paragraph {
  text-align: justify; /* Distributes words evenly in each line */
  color: #27637c;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}

.social-icon {
  color: #27637c;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #7d4f50;
}

.vk-icon {
  width: 4em;
  /* Adjust the width to match the size of FontAwesome icons */
  height: 4em;
  /* Adjust the height to match the size of FontAwesome icons */
  border-radius: 5px;
}